import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import resize from '@alpinejs/resize'

import 'htmx.org';

import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

// Testing Shoelace for Select Dropdown for now, not sure if final
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';

import './site/auth';
import './site/theme-switcher';
import './site/awards-search';
import './site/video-library';
import './site/carousels';

import.meta.glob([
    '../img/**',
]);

window.Splide = Splide;

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(resize);
Alpine.start();
