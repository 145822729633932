import Alpine from 'alpinejs';

Alpine.data('splideCarouselWithVideo', () => ({
    playingVideoId: null,
    currentPlayerInstance: null,
    splide: null,
    featuredCarousel: false,
    perPage: 1,
    gap: '1rem',
    padding: '20%',
    init() {
        this.$nextTick(() => {
            this.initSplide();
            this.splide.mount();
        });
    },
    initSplide() {
        let that = this;
        this.splide = new Splide(that.$refs.splide_carousel, {
            updateOnMove: true,
            type: 'loop',
            perPage: that.perPage,
            gap: that.gap,
            perMove: 1,
            lazyLoad: 'nearby',
            preloadPages: 3,
            breakpoints: {
                640: {
                    preloadPages: 3,
                    perPage: 1,
                    padding: this.padding,
                },
            },
        }).on( 'overflow', function ( isOverflow ) {
            that.splide.go( 0 );
            that.splide.options = {
                arrows    : isOverflow,
                pagination: isOverflow,
                drag      : isOverflow,
                clones    : isOverflow ? undefined : 0, // Toggle clones
            };
        });
    },
    splideBannerInit() {
        let that = this;
        this.splide.on('ready', function() {
                that.calculateCaptionHeight();
            })
            .on( 'move', function (newIndex) {
                that.slideIndex = newIndex + 1;
                that.updateHeight()
            })
            .on('resized', function() {
                that.calculateCaptionHeight();
                that.updateHeight();
            });
    },
    nextClick() {
        this.splide.go('+${i}');
    },
    prevClick() {
        this.splide.go('-${i}');
    },
    calculateWidthToTranslateEntireSlider() {
        // This isn't being used anywhere but keeping it here for if/when we activate the featured sliders
        let activeSlide = this.splide.Components.Elements.slides[this.splide.index];
        let activeSlideWidth = activeSlide.clientWidth;
        let translateAmount = (activeSlideWidth - (activeSlideWidth * .9))/2;
        this.$refs.splide__track_container.style.transform = 'translateX(-'+ translateAmount +'px)';
    },
    playVideoFromCarousel(videoId) {
        this.playingVideoId = videoId;
        this.$nextTick(() => {
            const gridItemElement = document.getElementById(videoId);
            const playerElement = gridItemElement.querySelector('.video-js');

            const playerId = playerElement.getAttribute('data-player');
            const embedId = playerElement.getAttribute('data-embed');

            const brightcove = window.bc[`${playerId}_${embedId}`] || window.bc;

            if (this.currentPlayerInstance) {
                this.currentPlayerInstance.dispose();
            }

            this.currentPlayerInstance = brightcove(playerElement);

            this.currentPlayerInstance.play();

            this.currentPlayerInstance.on('ended', () => {
                this.playNextVideo(gridItemElement);
            });
        });
    },

    playNextVideoFromCarousel(currentGridItemElement) {
        // This needs to be updated to work with the splide carousel
        const nextGridItemElement = currentGridItemElement.nextElementSibling;

        if (!nextGridItemElement) {
            const paginationContainer = document.querySelector('#video-library-pagination');

            if (!paginationContainer) {
                return;
            }

            const nextPageUrl = paginationContainer.getAttribute('data-next-page-url');

            if (!nextPageUrl) {
                return;
            }

            window.location = nextPageUrl + '#auto-play-' + window.scrollY;

            return;
        }

        const nextVideoId = nextGridItemElement.id.replace('video-gallery-item-', '');

        this.playVideo(nextVideoId);
    },
    calculateCaptionHeight() {
        let that = this;
        that.overlayHeight = 0;
        var overlays = document.querySelectorAll('.banner-caption-area');
        let pagination = document.getElementById('banner-pagination');
        for (var i=0; i < overlays.length; i++) {
            overlays[i].style.height = 'auto';
            if(overlays[i].offsetHeight > that.overlayHeight) {
                that.overlayHeight = overlays[i].offsetHeight;
                pagination.style.bottom = that.overlayHeight - 50 + 'px';
            }
        }
    },
    updateHeight() {
        let that = this;
        let slide = that.splide.Components.Slides.getAt( that.splide.index ).slide;
        slide.querySelector('.banner-caption-area').style.height = that.overlayHeight + 'px';
    }
}));
