import Alpine from 'alpinejs';

const emptyFormData =  () => ({
    search: '',
    winnersOnly: false,
    nomineeName: '',
    tvProgramTitle: '',
});

Alpine.data('awardsSearch', () => ({
    awardsCategoryOptions: [],
    tvNetworkOptions: [],
    productionCompanyOptions: [],
    isAdvancedSearch: false,

    formData: emptyFormData(),

    showResults: false,
    showResetButton: false,
    searchResults: [],
    pagination: [],
    totalHits: null,
    firstHit: null,
    lastHit: null,

    initialPage: 1,
    initialFilters: {}, // Can't set dropdown values before they're loaded with shoelace. Storing them here for initialization.
    initialized: false,

    currentUrlHash: '',

    async init() {
        this.currentUrlHash = window.location.hash || '#';

        await this.pullStateFromUrl();
        await Promise.all([
            this.fetchResults(this.initialPage),
            this.fetchDropdownOptions(),
        ]);

        window.addEventListener('hashchange', async () => {
            if (this.currentUrlHash === window.location.hash || (window.location.hash === '' && this.currentUrlHash === '#')) {
                return;
            }
            await this.pullStateFromUrl();
            await this.fetchResults(this.initialPage);
        });
    },

    //
    // Store and retrieve search state from URL.
    //  using hash instead of query string as advanced filters can get really long and the server does not need
    //  the parameters on the GET request.
    //
    async pullStateFromUrl() {
        const hashString = window.location.hash || '#?';
        if (!hashString.startsWith('#?')) {
            return;
        }
        const urlParams = new URLSearchParams(hashString.substring(2));

        this.formData.search = urlParams.get('q') || '';
        this.formData.winnersOnly = urlParams.get('wo') === '1';

        this.isAdvancedSearch = urlParams.get('adv') === '1';
        this.formData.nomineeName = urlParams.get('nominee') || '';
        this.formData.tvProgramTitle = urlParams.get('tvProgram') || '';

        const filters = await this.parseAdvancedFiltersFromUrlParam(urlParams.get('filters') || '');
        this.initialFilters.categories = filters.categories || [];
        this.initialFilters.tvNetworks = filters.tvNetworks || [];
        this.initialFilters.productionCompanies = filters.productionCompanies || [];

        if (this.initialized) {
            this.$refs.awardsCategorySelect.value = this.mapCategorySelectionFromValues(this.initialFilters.categories || []);
            this.$refs.tvNetworksSelect.value = this.initialFilters.tvNetworks || [];
            this.$refs.productionCompaniesSelect.value = this.initialFilters.productionCompanies || [];
        }

        this.initialPage = !isNaN(urlParams.get('p'))  && urlParams.get('p') > 0 ? urlParams.get('p') : 1;
    },

    async pushStateToUrl(searchParams, page, isAdvancedSearch) {
        const urlParams = {
            q: searchParams.search,
            wo: searchParams.winnersOnly ? '1' : '',
            p: page > 1 ? page : '',

            ...(isAdvancedSearch ? {
                    adv: '1',
                    nominee: searchParams.nomineeName,
                    tvProgram: searchParams.tvProgramTitle,
                    filters: await this.mapAdvancedDropdownFiltersForUrlParam(searchParams),
                }
                : {}),
        };

        for (const key in urlParams) {
            if (!urlParams[key]) {
                delete urlParams[key];
            }
        }

        if (Object.keys(urlParams).length === 0) {
            this.updateUrlHashSilently('');
            return;
        }

        this.updateUrlHashSilently('?' + new URLSearchParams(urlParams).toString());
    },

    async parseAdvancedFiltersFromUrlParam(filterString) {
        const decompressed = await this.decompressParam(filterString);

        if (!decompressed) {
            return {};
        }

        try {
            return JSON.parse(decompressed);
        } catch (e) {
            console.error('Error parsing advanced filters:', e);
            return {};
        }
    },

    async mapAdvancedDropdownFiltersForUrlParam(searchParams) {
        const filters = {
            categories: this.mapCategorySelection(this.$refs.awardsCategorySelect.value),
            tvNetworks: this.$refs.tvNetworksSelect.value,
            productionCompanies: this.$refs.productionCompaniesSelect.value,
        };

        for (const key in filters) {
            if (!filters[key].length) {
                delete filters[key];
            }
        }

        if (!Object.keys(filters).length) {
            return '';
        }

        return await this.compressParam(JSON.stringify(filters));
    },

    async fetchDropdownOptions() {
        const response = await fetch('/api/awards-search/dropdown-options');
        const data = await response.json();

        this.awardsCategoryOptions = data.awardsCategories.map((category, index) => ({
            id: 'cat-' + index,
            label: category.label,
        }));
        this.tvNetworkOptions = data.tvNetworks;
        this.productionCompanyOptions = data.productionCompanies;

        this.$refs.awardsCategorySelect.value = this.mapCategorySelectionFromValues(this.initialFilters.categories || []);
        this.$refs.tvNetworksSelect.value = this.initialFilters.tvNetworks || [];
        this.$refs.productionCompaniesSelect.value = this.initialFilters.productionCompanies || [];

        this.initialized = true;
    },

    resetForm() {
        this.formData = emptyFormData();
        this.$refs.awardsCategorySelect.value = [];
        this.$refs.tvNetworksSelect.value = [];
        this.$refs.productionCompaniesSelect.value = [];
        this.searchResults = [];
        this.pagination = [];
        this.totalHits = null;
        this.firstHit = null;
        this.lastHit = null;
        this.showResults = false;

        this.updateUrlHashSilently('');
    },

    async submitSearch() {
        await this.fetchResults();
    },

    async fetchResults(page = 1, scroll = false) {
        const searchParameters = this.isAdvancedSearch
            ? { ...this.formData,
                categories: this.initialized ? this.mapCategorySelection(this.$refs.awardsCategorySelect.value) : (this.initialFilters.categories || []),
                tvNetworks: this.initialized ? this.$refs.tvNetworksSelect.value : (this.initialFilters.tvNetworks || []),
                productionCompanies: this.initialized ? this.$refs.productionCompaniesSelect.value : (this.initialFilters.productionCompanies || []),
            }
            : { search: this.formData.search, winnersOnly: this.formData.winnersOnly };

        const response = await fetch('/api/awards-search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...searchParameters,
                page,
                baseUrl: window.location.pathname + '?' + new URLSearchParams(searchParameters).toString(),
            }),
        });

        if (this.initialized) {
            await this.pushStateToUrl(searchParameters, page, this.isAdvancedSearch);
        }

        const data = await response.json();


        this.searchResults = data.results;
        this.totalHits = data.totalHits;
        this.firstHit = data.firstHit;
        this.lastHit = data.lastHit
        this.pagination = data.pagination;
        this.showResults = true;

        // determine if formData is empty
        for (const key in this.formData) {
            if (this.formData[key]) {
                this.showResetButton = true;
            }
        }

        if (scroll && document.getElementById('awards-search-pagination-scroll-target')) {
            document.getElementById('awards-search-pagination-scroll-target').scrollIntoView({ behavior: 'smooth' });
        }
    },


    changePage(page) {
        if (page === null) {
            return;
        }

        this.fetchResults(page, true);
    },

    mapCategorySelection(selection) {
        if (!selection) {
            return selection;
        }

        return selection.map(category => {
            const option = this.awardsCategoryOptions.find(option => option.id === category);
            return option ? option.label : category;
        });
    },

    mapCategorySelectionFromValues(values) {
        if (!values) {
            return [];
        }

        return values.map(value => {
            const option = this.awardsCategoryOptions.find(option => option.label === value);
            return option ? option.id : value;
        });
    },

    updateUrlHashSilently(newHash) {
        if (typeof newHash !== 'string') {
            return;
        }
        if (!newHash.startsWith('#')) {
            newHash = '#' + newHash;
        }

        this.currentUrlHash = newHash;

        window.history.replaceState(null, null, this.currentUrlHash === '#' ? window.location.pathname : this.currentUrlHash);
    },

    async compressParam(value) {
        if (!value) {
            return '';
        }

        if (!'CompressionStream' in window) {
            return value;
        }

        const compressedStream1 = new Blob([value])
            .stream()
            .pipeThrough(new window.CompressionStream('gzip'));

        const bytes = new Uint8Array(await new Response(compressedStream1).arrayBuffer())
            .reduce((data, byte) => data + String.fromCharCode(byte), '');

        return 'gz:' + window.btoa(bytes);
    },

    async decompressParam(value) {
        if (!value) {
            return '';
        }

        if (!value.startsWith('gz:')) {
            return value;
        }

        if (!'DecompressionStream' in window) {
            return '';
        }

        const base64 = value.substring(3);
        const bytes = new Uint8Array(window.atob(base64).split('').map(char => char.charCodeAt(0)));

        const decompressedStreamReader = new Blob([bytes])
            .stream()
            .pipeThrough(new window.DecompressionStream('gzip'))

        return await new Response(await decompressedStreamReader).text();
    },
}));
